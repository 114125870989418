import { Box } from "@mui/material";
import { Minus, Plus } from "react-feather";
import { theme } from "../../libs/theme";

type Props = {
  map: google.maps.Map | null;
  type: "plus" | "minus";
};

export const Zoom = ({ map, type }: Props) => {
  const maxZoomLevel = 22;
  const minZoomLevel = 0;
  return (
    <Box
      sx={{
        width: "32px",
        height: "32px",
        borderRadius: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: theme.card.jaColor,
        background: "rgba(255, 255, 255, 0.6)",
        boxShadow: "0px 4px 25px rgba(0, 0, 0, 0.1)",
        backdropFilter: "blur(15px)",
        ":hover": {
          cursor: "pointer",
        },
      }}
      onClick={() => {
        if (map !== null) {
          const currentZoomLevel = map.getZoom();
          if (
            type === "plus" &&
            currentZoomLevel !== undefined &&
            currentZoomLevel < maxZoomLevel
          ) {
            map.setZoom(currentZoomLevel + 1);
          } else if (
            type === "minus" &&
            currentZoomLevel !== undefined &&
            currentZoomLevel > minZoomLevel
          ) {
            map.setZoom(currentZoomLevel - 1);
          }
        }
      }}
    >
      {type === "plus" ? (
        <Plus width="19px" height="19px" strokeWidth={2} />
      ) : (
        <Minus width="19px" height="19px" strokeWidth={2} />
      )}
    </Box>
  );
};

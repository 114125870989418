import { Box, Typography } from "@mui/material";
import { theme } from "../../libs/theme";
import { textJa } from "../../locales/textJa";

export const ErrorMessage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexFlow: "column",
      }}
    >
      <Typography
        fontFamily="Roboto, Helvetica, Arial, sans-serif"
        fontStyle="normal"
        fontWeight="400"
        fontSize="14px"
        lineHeight="17px"
        color={theme.card.mainTextColor}
      >
        {textJa.error.firstline}
      </Typography>
      <Typography
        fontFamily="Roboto, Helvetica, Arial, sans-serif"
        fontStyle="normal"
        fontWeight="400"
        fontSize="14px"
        lineHeight="17px"
        color={theme.card.mainTextColor}
      >
        {textJa.error.secondline}
      </Typography>
    </Box>
  );
};

export const textJa = {
  title: "耕×畜なび",
  initialText: {
    contents:
      "情報を見たい堆肥センターの\n場所を選択してください\n\nJAが運営する堆肥センターの他、\n地方公共団体が設置した\n堆肥センターも掲載しています",
  },
  search: {
    searchButton: "絞り込む",
    resetButton: "全て表示",
  },
  detail: {
    center: {
      label: {
        annualProduction: "年間生産量:",
        saleForms: "形状:",
        availabilityOfScattering: "散布:",
      },
      acceptableMaterials: ["肉用牛(繁殖)", "肉用牛(肥育)", "乳用牛", "豚", "ブロイラー", "採卵鶏", "その他"],
      saleForms: ["バラ・マッシュ", "バラ・ペレット", "袋・マッシュ", "袋・ペレット"],
      availabilityOfScattering: ["請負可", " - "],
      generalCrops: [
        "水稲",
        "小麦",
        "大豆",
        "茶",
        "だいこん",
        "ばれいしょ",
        "キャベツ",
        "たまねぎ",
        "トマト",
        "みかん",
        "りんご",
        "アスパラガス",
        "花き",
        "ぶどう",
        "その他",
      ],
      feedCrops: ["牧草", "WCS用稲", "飼料用トウモロコシ", "飼料用デントコーン", "その他"],
    },
    compost: {
      info: "堆肥情報",
      price: {
        bagged: " - 円/袋",
        individually: " - 円/バラ(トン当たり)",
      },
      ingredientLabel: "成分表",
      ingredients: {
        N: "窒素全量",
        K: "加里全量",
        P: "りん酸全量",
        H2O: "水分含有量",
        CN: "炭素窒素比",
        Cu: "銅全量",
        Zn: "亜鉛全量",
        Ca: "石灰全量",
      },
      ingredientsUnit: {
        N: "%",
        K: "%",
        P: "%",
        H2O: "%",
        CN: "",
        Cu: "%",
        Zn: "mg/kg",
        Ca: "mg/kg",
      },
      ingredientsCategory: ["乾物", "現物"],
    },
    noImage: "NO IMAGE",
  },
  error: {
    title: "エラー",
    firstline: "エラーが発生しました。",
    secondline: "時間をおいて再度お試しください。",
    okbutton: "OK",
  },
};

import { Box, Typography } from "@mui/material";
import { theme } from "../../libs/theme";
import { textJa } from "../../locales/textJa";
import { ErrorMessage } from "./ErrorMessage";
import { ModalCard } from "./ModalCard";
import { OkButton } from "./OkButton";

export const ErrorPerformance = () => {
  return (
    <ModalCard>
      <Box
        height="100%"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          flexFlow: "column",
        }}
      >
        <Typography
          fontFamily="Roboto, Helvetica, Arial, sans-serif"
          fontStyle="Bold"
          fontWeight="700"
          fontSize="16px"
          lineHeight="20px"
          color={theme.card.mainTextColor}
        >
          {textJa.error.title}
        </Typography>
        <ErrorMessage />
        <OkButton />
      </Box>
    </ModalCard>
  );
};

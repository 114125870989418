import { Box } from "@mui/material";
import { X } from "react-feather";
import { Dispatch, Fragment, ReactNode, SetStateAction } from "react";
import { theme } from "./libs/theme";

type Props = {
  marginPx?: number;
  show?: boolean;
  setShow?: Dispatch<SetStateAction<boolean>>;
  children: ReactNode;
  isMobile: boolean;
  isMobileWide: boolean;
};

const cardContainerStyles = (isMobileWide: boolean) => ({
  bgcolor: theme.card.bgColor,
  width: isMobileWide ? "46dvw" : "400px",
  height: `96dvh`,
  position: "absolute",
  top: `2dvh`,
  right: `1dvw`,
  zIndex: 2,
  boxShadow: "0px 4px 25px rgba(0, 0, 0, 0.1)",
  backdropFilter: "blur(15px)",
  borderRadius: "24px",
  overflowY: "scroll",
  ...theme.card.scrollbar,
});
const cardContainerStylesMobile = {
  bgcolor: theme.card.bgColor,
  width: "95dvw",
  height: `90dvh`,
  position: "absolute",
  left: 0,
  bottom: 0,
  zIndex: 2,
  boxShadow: "0px 4px 25px rgba(0, 0, 0, 0.1)",
  backdropFilter: "blur(15px)",
  overflowY: "scroll",
  ...theme.card.scrollbar,
};

const baseCloseButtonStyles = (isMobile: boolean) => ({
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "32px",
  height: "32px",
  zIndex: 2,
  bgcolor: theme.card.button.close.bgColor,
  color: theme.card.button.textColor,
  borderRadius: "50%",
  ":hover": {
    cursor: "pointer",
  },
  top: isMobile ? "10dvh" : "3px",
  right: isMobile ? "2dvw" : "3px",
});

export const Card = ({ show, setShow, children, isMobile, isMobileWide }: Props) => {
  if (show && setShow !== undefined) {
    return (
      <Fragment>
        <Box sx={isMobile ? cardContainerStylesMobile : cardContainerStyles(isMobileWide)}>{children}</Box>
        <Box sx={baseCloseButtonStyles(isMobile)} onClick={() => setShow(false)}>
          <X width="1em" height="1em" strokeWidth={5} />
        </Box>
      </Fragment>
    );
  } else {
    return <Fragment></Fragment>;
  }
};

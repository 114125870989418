import { Box } from "@mui/material";
import { CurrentLocation } from "./CurrentLocation";
import { Zoom } from "./Zoom";

type Props = {
  map: google.maps.Map | null;
  isMobile: boolean;
};

const controlsStyles = {
  position: "absolute",
  left: "304px",
  bottom: "17px",
  zIndex: 2,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "32px",
  height: "120px",
  gap: "12px",
};

const controlsStylesMobile = {
  position: "absolute",
  left: "16px",
  bottom: "40px",
  zIndex: 2,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "32px",
  height: "120px",
  gap: "12px",
};

export const Controls = ({ map, isMobile }: Props) => {
  return (
    <Box sx={isMobile ? controlsStylesMobile : controlsStyles}>
      <CurrentLocation map={map} />
      <Zoom map={map} type="plus" />
      <Zoom map={map} type="minus" />
    </Box>
  );
};

import { Box, Button, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { theme } from "../../libs/theme";
import { textJa } from "../../locales/textJa";
import { Pin } from "../../types/api";
import { searchFunction } from "../../utils/functions";
import { LogoIcon } from "./LogoIcon";
import { SearchBox } from "./SearchBox";

type Props = {
  setPins: Dispatch<SetStateAction<Pin[]>>;
  allPins: Pin[];
  isMobile: boolean;
  isMobileWide: boolean;
  setIsSearchVisible: Dispatch<SetStateAction<boolean>>;
};
export const Search = ({ setPins, allPins, isMobile, isMobileWide, setIsSearchVisible }: Props) => {
  const [materialConditions, setMaterialConditions] = useState<number[]>([]);
  const [annualProductionCondition, setAnnualProductionCondition] = useState<number[]>([]);
  const [saleFormConditions, setSaleFormConditions] = useState<number[]>([]);
  const [availabilityOfScatterCondition, setAvailabilityOfScatterCondition] = useState<number[]>([]);
  const [sprayingResultsCondition, setSprayingResultsCondition] = useState<number[]>([]);
  const [generalCropsCondition, setGeneralCropsCondition] = useState<number[]>([]);
  const [feedCropsCondition, setFeedCropsCondition] = useState<number[]>([]);

  const stateArray = [
    materialConditions,
    saleFormConditions,
    availabilityOfScatterCondition,
    annualProductionCondition,
    sprayingResultsCondition,
    generalCropsCondition,
    feedCropsCondition,
  ];
  const setStateArray = [
    setMaterialConditions,
    setSaleFormConditions,
    setAvailabilityOfScatterCondition,
    setAnnualProductionCondition,
    setSprayingResultsCondition,
    setGeneralCropsCondition,
    setFeedCropsCondition,
  ];

  const handleSearchFunction = useCallback(searchFunction, []);

  const logoBoxHeightPx = 160;
  const searchBoxHeightPx = 106;
  const commonTypographyStyles = {
    fontFamily: "Noto Sans JP, Roboto, Helvetica, Arial, sans-serif",
    textAlign: "center",
  };
  const titleStyles = {
    ...commonTypographyStyles,
    color: theme.card.mainTextColor,
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "22px",
    marginTop: "21px",
  };
  const subTitleStyles = {
    ...commonTypographyStyles,
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "19px",
    marginTop: "30px",
  };
  const subTitleStylesMobile = {
    ...commonTypographyStyles,
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "19px",
    marginTop: "15px",
  };
  const buttonStyle = {
    boxShadow: "0px 4px 25px rgba(0, 0, 0, 0.1)",
    borderRadius: "20px",
    width: "85%",
    height: "37px",
    marginBottom: "16px",
    color: theme.card.button.textColor,
    textAlign: "center",
    fontFamily: "Noto Sans JP, Roboto, Helvetica, Arial, sans-serif",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "19px",
  };

  const searchBoxStyles = {
    bgcolor: theme.card.bgColor,
    width: "280px",
    height: isMobileWide ? `calc(92dvh)` : `calc(100dvh - ${16 * 2}px)`,
    position: "absolute",
    top: "16px",
    left: "16px",
    zIndex: 100,
    boxShadow: "0px 4px 25px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(15px)",
    borderRadius: "24px",
  };
  const searchBoxStylesMobile = {
    bgcolor: theme.card.bgColor,
    width: "80dvw",
    position: "absolute",
    height: `90dvh`,
    right: 0,
    bottom: 0,
    zIndex: 100,
    boxShadow: "0px 4px 25px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(15px)",
  };

  const buttonContainerBaseStyles = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  const buttonContainerStyles = {
    ...buttonContainerBaseStyles,
    height: isMobileWide ? `30dvh` : `${searchBoxHeightPx}px`,
  };

  const buttonContainerStylesMobile = {
    ...buttonContainerBaseStyles,
  };

  return (
    <Box sx={isMobile ? searchBoxStylesMobile : searchBoxStyles}>
      {!isMobile && !isMobileWide && (
        <Box display="flex" flexDirection="column" alignItems="center" height={`${logoBoxHeightPx}px`}>
          <Typography sx={titleStyles}>{textJa.title}</Typography>
          <Box paddingTop="20px">
            <LogoIcon />
          </Box>
          <Typography sx={subTitleStyles}>条件を選択して堆肥センターを検索</Typography>
        </Box>
      )}
      {(isMobile || isMobileWide) && (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography sx={subTitleStylesMobile}>条件を選択して堆肥センターを検索</Typography>
        </Box>
      )}

      <SearchBox
        logoBoxHeightPx={logoBoxHeightPx}
        searchBoxHeightPx={isMobile || isMobileWide ? -2 : searchBoxHeightPx}
        stateArray={stateArray}
        setStateArray={setStateArray}
        isMobileWide={isMobileWide}
      />
      <Box sx={isMobile ? buttonContainerStylesMobile : buttonContainerStyles}>
        <Button
          sx={{
            ...buttonStyle,
            background: theme.card.button.search.searchBgColor,
          }}
          onClick={() => {
            handleSearchFunction(
              setPins,
              allPins,
              stateArray[0] as number[],
              stateArray[1] as number[],
              stateArray[2] as number[],
              stateArray[3] as number[],
              stateArray[4] as number[],
              stateArray[5] as number[],
              stateArray[6] as number[]
            );
            setIsSearchVisible(false);
          }}
        >
          {textJa.search.searchButton}
        </Button>
        <Button
          sx={{
            ...buttonStyle,
            background: theme.card.button.search.resetBgColor,
            ":hover": {
              background: theme.card.button.search.resetBgColor,
            },
          }}
          onClick={() => {
            setPins(allPins);
            setIsSearchVisible(false);
          }}
        >
          {textJa.search.resetButton}
        </Button>
      </Box>
    </Box>
  );
};

import { Box, styled, Typography } from "@mui/material";
import { Calendar, MapPin, Phone } from "react-feather";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { theme } from "../../libs/theme";
import { textJa } from "../../locales/textJa";
import { detailDataType } from "../../types/api";
import { NoImage } from "./NoImage";
import { ReactComponent as BagSeedlingIcon } from "../../icons/bag-seedling-light.svg";
import { ReactComponent as CowIcon } from "../../icons/cow-light.svg";
import { ReactComponent as TruckIcon } from "../../icons/truck-light.svg";
import { ReactComponent as WarehouseIcon } from "../../icons/warehouse-light.svg";

const StyledTypography = styled(Typography)(() => ({
  color: theme.card.mainTextColor,
  fontFamily: "Noto Sans JP, Roboto, Helvetica, Arial, sans-serif",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "19px",
}));

type Props = {
  detail: detailDataType;
  loading: boolean;
  isMobile: boolean;
  isMobileWide: boolean;
};

const iconStyle = {
  fill: "#999999",
};

export const Center = ({ detail, loading, isMobile, isMobileWide }: Props) => {
  return (
    <SkeletonTheme baseColor={theme.card.detail.skeleton}>
      <Box width="100%">
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" marginBottom="22px">
          {loading ? (
            <Box
              width="364px"
              height="220px"
              borderRadius="16px"
              overflow="hidden"
              sx={{
                objectFit: "cover",
              }}
            >
              <Skeleton height="220px" width={400} />
            </Box>
          ) : detail.detail.center.image !== null ? (
            <Box
              width="364px"
              height="220px"
              borderRadius="16px"
              overflow="hidden"
              sx={{
                objectFit: "cover",
              }}
            >
              <img
                src={`data:image/png;base64,${detail.detail.center.image}`}
                alt={detail.detail.center.name}
                width="364px"
                height="220px"
              />
            </Box>
          ) : (
            <NoImage type="center" />
          )}
        </Box>
        {/* 堆肥センター名 */}
        <Box marginBottom="11px">
          {loading ? (
            <Box width="100%" overflow="hidden">
              <Skeleton height="24px" />
            </Box>
          ) : (
            <Typography
              fontFamily="Noto Sans JP, Roboto, Helvetica, Arial, sans-serif"
              fontStyle="normal"
              fontWeight="700"
              fontSize="20px"
              lineHeight="24px"
              color={theme.card.mainTextColor}
              id="center"
            >
              {detail.detail.center.name}
            </Typography>
          )}
        </Box>
        {/* 所属JA名 */}
        <Box marginBottom="15px">
          {loading ? (
            <Box width="100%" overflow="hidden">
              <Skeleton height="19px" />
            </Box>
          ) : (
            <Typography
              fontFamily="Noto Sans JP, Roboto, Helvetica, Arial, sans-serif"
              fontStyle="normal"
              fontWeight={400}
              fontSize="16px"
              lineHeight="19px"
              color={theme.card.subTextColor}
            >
              {detail.detail.center.jaName}
            </Typography>
          )}
        </Box>
        {/* 住所 */}
        <Box display="flex" alignItems="center" marginBottom="8px">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            color={theme.card.detail.icon}
            marginRight="16px"
          >
            <MapPin strokeWidth={2} />
          </Box>
          {loading ? (
            <Box width="100%" overflow="hidden">
              <Skeleton height="24px" />
            </Box>
          ) : (
            <StyledTypography>{detail.detail.center.address}</StyledTypography>
          )}
        </Box>
        {/* 電話番号 */}
        <Box display="flex" alignItems="center" marginBottom="8px">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            color={theme.card.detail.icon}
            marginRight="16px"
          >
            <Phone strokeWidth={2} />
          </Box>
          {loading ? (
            <Box width="100%" overflow="hidden">
              <Skeleton height="24px" />
            </Box>
          ) : (
            <StyledTypography>
              {detail.detail.center.contact !== null ? (
                isMobile || isMobileWide ? (
                  <a
                    href={`tel:${detail.detail.center.contact}`}
                    style={{
                      color: theme.card.mainTextColor,
                      textDecoration: "underline",
                      textDecorationColor: theme.card.mainTextColor,
                      textDecorationThickness: "1px",
                    }}
                  >
                    {detail.detail.center.contact}
                  </a>
                ) : (
                  detail.detail.center.contact
                )
              ) : (
                "-"
              )}
            </StyledTypography>
          )}
        </Box>
        {/* 設立年度 */}
        <Box display="flex" alignItems="center" marginBottom="8px">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            color={theme.card.detail.icon}
            marginRight="16px"
          >
            <Calendar strokeWidth={2} />
          </Box>
          {loading ? (
            <Box width="100%" overflow="hidden">
              <Skeleton height="24px" />
            </Box>
          ) : (
            <StyledTypography>
              {detail.detail.center.establish !== null ? `${detail.detail.center.establish}年設立` : "-"}
            </StyledTypography>
          )}
        </Box>
        {/* 年間生産量 */}
        <Box display="flex" alignItems="center" marginBottom="8px">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            color={theme.card.detail.icon}
            marginRight="16px"
          >
            <WarehouseIcon style={iconStyle} width="24px" height="24px" />
          </Box>
          {loading ? (
            <Box width="100%" overflow="hidden">
              <Skeleton height="24px" />
            </Box>
          ) : (
            <StyledTypography>
              {detail.detail.center.annualProduction !== null
                ? `${
                    textJa.detail.center.label.annualProduction
                  } ${detail.detail.center.annualProduction.toLocaleString()}t`
                : `${textJa.detail.center.label.annualProduction} -`}
            </StyledTypography>
          )}
        </Box>
        {/* 受入原料 */}
        <Box display="flex" alignItems="start" marginBottom="8px">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            color={theme.card.detail.icon}
            marginRight="16px"
          >
            <CowIcon style={iconStyle} width="24px" height="24px" />
          </Box>
          {loading ? (
            <Box width="100%" overflow="hidden">
              <Skeleton height="24px" />
            </Box>
          ) : detail.detail.center.acceptableMaterials.length !== 0 ? (
            <Box display="flex" flexWrap="wrap" alignItems="center" gap="10px">
              {detail.detail.center.acceptableMaterials &&
                detail.detail.center.acceptableMaterials.map((acceptableMaterial) => (
                  <Box
                    key={acceptableMaterial}
                    height="24px"
                    // Figma上の数値とは異なるが、実際に計測した値
                    paddingX="14px"
                    // Figmaで設定されている幅73pxからpaddingXを引いた値
                    minWidth="45px"
                    bgcolor={theme.card.detail.acceptableMaterials[acceptableMaterial]}
                    borderRadius="24px"
                    color={theme.card.button.textColor}
                  >
                    <Typography
                      fontFamily="Noto Sans JP, Roboto, Helvetica, Arial, sans-serif"
                      fontStyle="normal"
                      fontWeight="700"
                      fontSize="14px"
                      lineHeight="22px"
                      letterSpacing="-0.02em"
                      textAlign="center"
                    >
                      {textJa.detail.center.acceptableMaterials[acceptableMaterial]}
                    </Typography>
                  </Box>
                ))}
            </Box>
          ) : (
            "-"
          )}
        </Box>
        {/* 販売形状 */}
        <Box display="flex" alignItems="start" marginBottom="8px">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            color={theme.card.detail.icon}
            marginRight="16px"
          >
            <BagSeedlingIcon style={iconStyle} width="24px" height="24px" />
          </Box>
          {loading ? (
            <Box width="100%" overflow="hidden">
              <Skeleton height="24px" />
            </Box>
          ) : (
            <>
              <Box display="flex" justifyContent="center" alignItems="center" height="24px">
                <StyledTypography whiteSpace="nowrap">
                  {detail.detail.center.saleForms.length !== 0
                    ? textJa.detail.center.label.saleForms
                    : `${textJa.detail.center.label.saleForms} -`}
                </StyledTypography>
              </Box>
              <Box display="flex" flexWrap="wrap" alignItems="center" marginLeft="8px">
                {detail.detail.center.saleForms &&
                  detail.detail.center.saleForms.map((saleForm, index) => (
                    <Box key={saleForm} display="flex" flexWrap="wrap" alignItems="center" height="24px">
                      <Typography
                        fontFamily="Noto Sans JP, Roboto, Helvetica, Arial, sans-serif"
                        fontStyle="normal"
                        fontWeight="400"
                        fontSize="14px"
                        lineHeight="17px"
                      >
                        {index !== detail.detail.center.saleForms.length - 1
                          ? `${textJa.detail.center.saleForms[saleForm]}/`
                          : textJa.detail.center.saleForms[saleForm]}
                      </Typography>
                    </Box>
                  ))}
              </Box>
            </>
          )}
        </Box>
        {/* 散布請負 */}
        <Box display="flex" alignItems="center" marginBottom="8px">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            color={theme.card.detail.icon}
            marginRight="16px"
          >
            <TruckIcon style={iconStyle} width="24px" height="24px" />
          </Box>
          {loading ? (
            <Box width="100%" overflow="hidden">
              <Skeleton height="24px" />
            </Box>
          ) : (
            <StyledTypography>
              {detail.detail.center.availabilityOfScattering !== null
                ? `${textJa.detail.center.label.availabilityOfScattering} ${
                    textJa.detail.center.availabilityOfScattering[detail.detail.center.availabilityOfScattering]
                  }`
                : `${textJa.detail.center.label.availabilityOfScattering} -`}
            </StyledTypography>
          )}
        </Box>
      </Box>
    </SkeletonTheme>
  );
};

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { ChangeEvent, Dispatch, SetStateAction, useCallback, useState } from "react";
import { PlusSquare, MinusSquare } from "react-feather";
import { theme } from "../../libs/theme";
import { changeSearchConditionsFunction } from "../../utils/functions";
import { ReactComponent as BagSeedlingIcon } from "../../icons/bag-seedling-light.svg";
import { ReactComponent as CowIcon } from "../../icons/cow-light.svg";
import { ReactComponent as TruckIcon } from "../../icons/truck-light.svg";
import { ReactComponent as WarehouseIcon } from "../../icons/warehouse-light.svg";
import { ReactComponent as WheatAwnIcon } from "../../icons/wheat-awn-light.svg";

type Props = {
  logoBoxHeightPx: number;
  searchBoxHeightPx: number;
  stateArray: Array<number[] | number>;
  setStateArray: Array<Dispatch<SetStateAction<number[]>> | Dispatch<SetStateAction<number>>>;
  isMobileWide: boolean;
};

export const SearchBox = ({ logoBoxHeightPx, searchBoxHeightPx, stateArray, setStateArray, isMobileWide }: Props) => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const searchBoxArray = [
    {
      id: 0,
      name: "原料",
      icon: <CowIcon width="24px" height="24px" />,
      type: "check",
      children: [
        { id: 0, name: "肉用牛（繁殖）" },
        { id: 1, name: "肉用牛（肥育）" },
        { id: 2, name: "乳用牛" },
        { id: 3, name: "豚" },
        { id: 4, name: "ブロイラー" },
        { id: 5, name: "採卵鶏" },
        { id: 6, name: "その他" },
      ],
    },

    {
      id: 1,
      name: "形状",
      icon: <BagSeedlingIcon width="24px" height="24px" />,
      type: "check",
      children: [
        { id: 0, name: "バラ・マッシュ" },
        { id: 1, name: "バラ・ペレット" },
        { id: 2, name: "袋・マッシュ" },
        { id: 3, name: "袋・ペレット" },
      ],
    },
    {
      id: 2,
      name: "散布",
      icon: <TruckIcon width="24px" height="24px" />,
      type: "check",
      children: [
        { id: 0, name: "請負可" },
        // { id: 1, name: "不可" },
      ],
    },
    {
      id: 3,
      name: "年間生産量",
      icon: <WarehouseIcon width="24px" height="24px" />,
      type: "check",
      children: [
        { id: 0, name: "~ 499t" },
        { id: 1, name: "500t ~ 999t" },
        { id: 2, name: "1,000t ~ 1,999t" },
        { id: 3, name: "2,000t ~ 2,999t" },
        { id: 4, name: "3,000t ~" },
      ],
    },
    {
      id: 4,
      name: "施用実績",
      icon: <WheatAwnIcon width="24px" height="24px" />,
      type: "check",
      children: [],
    },
    {
      id: 5,
      name: "普通作物",
      icon: <Box width="0px" height="24px" />,
      type: "check",
      children: [
        { id: 0, name: "水稲" },
        { id: 1, name: "小麦" },
        { id: 2, name: "大豆" },
        { id: 3, name: "茶" },
        { id: 4, name: "だいこん" },
        { id: 5, name: "ばれいしょ" },
        { id: 6, name: "キャベツ" },
        { id: 7, name: "たまねぎ" },
        { id: 8, name: "トマト" },
        { id: 9, name: "みかん" },
        { id: 10, name: "りんご" },
        { id: 11, name: "アスパラガス" },
        { id: 12, name: "花き" },
        { id: 13, name: "ぶどう" },
        { id: 14, name: "その他" },
      ],
    },
    {
      id: 6,
      name: "飼料作物",
      icon: <Box width="0px" height="24px" />,
      type: "check",
      children: [
        { id: 0, name: "牧草" },
        { id: 1, name: "WCS用稲" },
        { id: 2, name: "飼料用トウモロコシ" },
        { id: 3, name: "飼料用デントコーン" },
        { id: 4, name: "その他" },
      ],
    },
  ];

  const handleChangeSearchConditionsFunction = useCallback(changeSearchConditionsFunction, []);

  const expandIconStyle = {
    paddingTop: "5px",
    color: "rgba(0, 0, 0, 0.54)",
    width: "20px",
    height: "20px",
  };

  const accordionSummary = {
    padding: 0,
    "& .MuiAccordionSummary-content": {
      margin: 1,
    },
  };

  const accordionSummaryForSubItem = {
    ...accordionSummary,
    "&.MuiAccordionSummary-root": {
      marginTop: "-12px",
    },
  };

  const marginTopPX = 12;
  const subAccordionList = [5, 6];
  return (
    <Box
      height={`calc(100% - ${logoBoxHeightPx}px - ${searchBoxHeightPx}px - ${marginTopPX}px)`}
      paddingX="16px"
      marginTop={`${marginTopPX}px`}
      sx={{
        overflowY: "scroll",
        ...theme.card.scrollbar,
      }}
    >
      {searchBoxArray &&
        searchBoxArray.map((item) => (
          <Accordion
            disableGutters
            key={item.name}
            expanded={item.id !== 4 && expanded === item.name}
            onChange={item.id !== 4 ? handleChange(item.name) : undefined}
            sx={{
              backgroundColor: "transparent",
              boxShadow: "none",
              "&:before": {
                display: "none",
              },
              "& .MuiAccordionSummary-root": {
                padding: 0,
              },
              "& .MuiAccordionDetails-root": {
                padding: 0,
              },
            }}
          >
            <AccordionSummary
              expandIcon={
                item.id !== 4 &&
                (expanded === item.name ? (
                  <MinusSquare style={expandIconStyle} />
                ) : (
                  <PlusSquare style={expandIconStyle} />
                ))
              }
              aria-controls={`panel${item.id + 1}-content`}
              id={`panel${item.id + 1}-header`}
              sx={subAccordionList.includes(item.id) ? accordionSummaryForSubItem : accordionSummary}
            >
              <Box display="flex" color={theme.card.searchComponents.label} marginY={isMobileWide ? "0px" : "10px"}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  marginLeft={subAccordionList.includes(item.id) ? "1.5em" : ""}
                >
                  {item.icon}
                  <Typography
                    fontFamily="Noto Sans JP, Roboto, Helvetica, Arial, sans-serif"
                    fontSize={subAccordionList.includes(item.id) || isMobileWide ? "15px" : "18px"}
                    fontWeight={subAccordionList.includes(item.id) ? 500 : 600}
                    lineHeight="12px"
                    marginLeft="12px"
                    color="#333333"
                  >
                    {item.name}
                  </Typography>
                </Box>
              </Box>
            </AccordionSummary>

            <AccordionDetails>
              {item.type === "check" ? (
                <Box paddingLeft={subAccordionList.includes(item.id) ? "2em" : "8px"}>
                  <FormControl>
                    <FormGroup>
                      {item.children &&
                        item.children.map((child) => (
                          <FormControlLabel
                            key={child.name}
                            control={
                              <Checkbox
                                name={child.name}
                                value={child.id}
                                sx={{
                                  color: theme.card.searchComponents.button.borderColor,
                                  "&.Mui-checked": {
                                    color: theme.card.searchComponents.button.checkedColor,
                                  },
                                  marginLeft: "8px",
                                }}
                                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                  handleChangeSearchConditionsFunction(
                                    event,
                                    stateArray[item.id],
                                    setStateArray[item.id]
                                  )
                                }
                              />
                            }
                            label={
                              <Typography
                                fontFamily="Noto Sans JP, Roboto, Helvetica, Arial, sans-serif"
                                // fontStyle="normal"
                                fontWeight="500"
                                fontSize="15px"
                                lineHeight="20px"
                                letterSpacing="0.15px"
                                color={theme.card.searchComponents.button.label}
                              >
                                {child.name}
                              </Typography>
                            }
                            sx={{
                              height: "32px",
                            }}
                          />
                        ))}
                    </FormGroup>
                  </FormControl>
                </Box>
              ) : (
                <Box paddingLeft="8px">
                  <FormControl>
                    <RadioGroup row={item.children.length > 2 ? false : true} defaultValue={item.children[0].id}>
                      {item.children &&
                        item.children.map((child) => (
                          <FormControlLabel
                            key={child.name}
                            value={child.id}
                            control={
                              <Radio
                                sx={{
                                  color: theme.card.searchComponents.button.borderColor,
                                  "&.Mui-checked": {
                                    color: theme.card.searchComponents.button.checkedColor,
                                  },
                                }}
                                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                  handleChangeSearchConditionsFunction(
                                    event,
                                    stateArray[item.id],
                                    setStateArray[item.id]
                                  )
                                }
                              />
                            }
                            label={
                              <Typography
                                fontFamily="Noto Sans JP, Roboto, Helvetica, Arial, sans-serif"
                                fontStyle="normal"
                                fontWeight="400"
                                fontSize="14px"
                                lineHeight="20px"
                                letterSpacing="0.15px"
                                color={theme.card.searchComponents.button.label}
                              >
                                {child.name}
                              </Typography>
                            }
                            sx={{
                              height: item.children.length > 2 ? "32px" : "40px",
                            }}
                          />
                        ))}
                    </RadioGroup>
                  </FormControl>
                </Box>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
    </Box>
  );
};

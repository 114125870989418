import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { Pin } from "../types/api";

const checkIsTheSameValueInTwoArrays = (dataArray: number[], conditionsArray: number[]): boolean => {
  if (conditionsArray.length === 0) return true;
  // 条件配列をフィルターし、データとの一致したものだけ残す。
  // 絞り込み一致した条件配列は、フィルターしても元の条件配列から要素数は減少しない。
  const filteredArray = conditionsArray.filter((condition) => dataArray.includes(condition));
  return filteredArray.length === conditionsArray.length;
};

const checkIsValueInRange = (value: number | null, array: number[]): boolean => {
  if (array.length === 0) return true;
  let transval = 0;
  if (value === null) return false;
  if (0 <= value && value < 500) {
    transval = 0;
  } else if (499 < value && value < 1000) {
    transval = 1;
  } else if (999 < value && value < 2000) {
    transval = 2;
  } else if (1999 < value && value < 3000) {
    transval = 3;
  } else {
    // 2999 < value
    transval = 4;
  }
  return array.includes(transval);
};

const checkIsValueInArray = (value: number | null, array: number[]): boolean => {
  if (array.length === 0) return true;
  if (value === null) return false;
  else {
    return array.includes(value);
  }
};

export const searchFunction = (
  setPins: Dispatch<SetStateAction<Pin[] | []>>,
  allPins: Pin[],
  acceptableMaterialConditions: number[],
  saleFormConditions: number[],
  availabilityOfScatterCondition: number[],
  annualProductionCondition: number[],
  sprayingResultsCondition: number[],
  generalCropsCondition: number[],
  feedCropsCondition: number[]
) => {
  const updatedList: Pin[] = [];
  allPins.forEach((pin: Pin) => {
    if (
      checkIsTheSameValueInTwoArrays(pin.acceptableMaterials, acceptableMaterialConditions) &&
      checkIsValueInRange(pin.annualProduction, annualProductionCondition) &&
      checkIsTheSameValueInTwoArrays(pin.saleForms, saleFormConditions) &&
      checkIsValueInArray(pin.availabilityOfScattering, availabilityOfScatterCondition) &&
      checkIsTheSameValueInTwoArrays(pin.generalCrops, generalCropsCondition) &&
      checkIsTheSameValueInTwoArrays(pin.feedCrops, feedCropsCondition)
    ) {
      updatedList.push(pin);
    }
  });
  setPins(updatedList);
};

export const changeSearchConditionsFunction = (
  event: ChangeEvent<HTMLInputElement>,
  state: number[] | number,
  setState: Dispatch<SetStateAction<number[]>> | Dispatch<SetStateAction<number>>
) => {
  if (typeof state === "number") {
    (setState as Dispatch<SetStateAction<number>>)(Number(event.target.value) as number);
  } else {
    if (event.target.checked) {
      (setState as Dispatch<SetStateAction<number[]>>)(Array.from(new Set([...state, Number(event.target.value)])));
    } else {
      (setState as Dispatch<SetStateAction<number[]>>)(state.filter((i) => i !== Number(event.target.value)));
    }
  }
};

import { Box, Typography } from "@mui/material";
import noImage from "../../images/noImage.png";
import noImageOnCenter from "../../images/noImageOnCenter.png";
import noImageOnCompost from "../../images/noImageOnCompost.png";
import { theme } from "../../libs/theme";
import { textJa } from "../../locales/textJa";

type Props = {
  type: "center" | "compost";
};

export const NoImage = ({ type }: Props) => {
  if (type === "center") {
    return (
      <Box
        position="relative"
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="220px"
      >
        <Box position="absolute" zIndex="1" height="220px">
          <img
            src={noImageOnCenter}
            alt="noImageOnCenter"
            width="100%"
            height="220px"
          />
        </Box>
        <Box
          position="absolute"
          zIndex="2"
          left="50%"
          top="50%"
          sx={{
            transform: "translate(-50%, -50%)",
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <img src={noImage} alt="noImage" height="32px" />
          <Typography
            fontFamily="Noto Sans JP, Roboto, Helvetica, Arial, sans-serif"
            fontStyle="normal"
            fontWeight="700"
            fontSize="27px"
            lineHeight="33px"
            color={theme.card.detail.noImage.color}
            sx={{
              textShadow: theme.card.detail.noImage.textShadow.compost,
            }}
            whiteSpace="nowrap"
            marginLeft="16px"
          >
            {textJa.detail.noImage}
          </Typography>
        </Box>
      </Box>
    );
  } else {
    return (
      <Box position="relative" width="114px" height="114px">
        <Box
          position="absolute"
          zIndex="1"
          left="50%"
          top="50%"
          sx={{
            transform: "translate(-50%, -50%)",
            objectFit: "cover",
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <img
            src={noImageOnCompost}
            alt="noImageOnCompost"
            width="114px"
            height="114px"
          />
        </Box>
        <Box
          position="absolute"
          zIndex="2"
          left="50%"
          top="50%"
          sx={{
            transform: "translate(-50%, -50%)",
          }}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <img src={noImage} alt="noImage" height="23px" />
          <Typography
            fontFamily="Noto Sans JP, Roboto, Helvetica, Arial, sans-serif"
            fontStyle="normal"
            fontWeight="700"
            fontSize="18px"
            lineHeight="22px"
            color={theme.card.detail.noImage.color}
            sx={{
              textShadow: theme.card.detail.noImage.textShadow.compost,
            }}
            whiteSpace="nowrap"
            marginTop="8px"
          >
            {textJa.detail.noImage}
          </Typography>
        </Box>
      </Box>
    );
  }
};

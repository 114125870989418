import { Box, styled, Typography, Divider } from "@mui/material";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { theme } from "../../libs/theme";
import { textJa } from "../../locales/textJa";
import { ReactComponent as WheatAwnIcon } from "../../icons/wheat-awn-light.svg";
import { compostType } from "../../types/api";

const StyledTypography = styled(Typography)(() => ({
  color: theme.card.mainTextColor,
  fontFamily: "Noto Sans JP, Roboto, Helvetica, Arial, sans-serif",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "19px",
}));

type Props = {
  detail: compostType;
  loading: boolean;
};
const iconStyle = {
  fill: "#999999",
};
export const Achievements = ({ detail, loading }: Props) => {
  return (
    <SkeletonTheme baseColor={theme.card.detail.skeleton}>
      <Box width="100%">
        <Box display="flex" alignItems="center" marginBottom="14px">
          <Box display="flex" justifyContent="center" alignItems="center" color={theme.card.detail.icon}>
            <WheatAwnIcon style={iconStyle} width="24px" height="24px" />
          </Box>
          <StyledTypography marginLeft="16px">施用実績（普通作物）</StyledTypography>
        </Box>
        <Box display="flex" alignItems="start" marginBottom="8px">
          {loading ? (
            <Box width="100%" overflow="hidden">
              <Skeleton height="24px" />
            </Box>
          ) : detail.generalCrops.length !== 0 ? (
            <Box display="flex" flexWrap="wrap" alignItems="center" gap="10px">
              {detail.generalCrops &&
                detail.generalCrops.map((generalCrop) => (
                  <Box
                    key={generalCrop}
                    height="24px"
                    paddingX="14px"
                    minWidth="45px"
                    bgcolor={theme.card.detail.generalCrops[generalCrop]}
                    borderRadius="24px"
                    color={theme.card.button.textColor}
                  >
                    <Typography
                      fontFamily="Noto Sans JP, Roboto, Helvetica, Arial, sans-serif"
                      fontStyle="normal"
                      fontWeight="700"
                      fontSize="14px"
                      lineHeight="22px"
                      letterSpacing="-0.02em"
                      textAlign="center"
                      id="generalCrop"
                    >
                      {textJa.detail.center.generalCrops[generalCrop]}
                    </Typography>
                  </Box>
                ))}
            </Box>
          ) : (
            "-"
          )}
        </Box>
        <Box display="flex" alignItems="center" marginBottom="14px">
          <Box display="flex" justifyContent="center" alignItems="center" color={theme.card.detail.icon}>
            <WheatAwnIcon style={iconStyle} width="24px" height="24px" />
          </Box>
          <StyledTypography marginLeft="16px">施用実績（飼料作物）</StyledTypography>
        </Box>
        <Box display="flex" alignItems="start" marginBottom="8px">
          {loading ? (
            <Box width="100%" overflow="hidden">
              <Skeleton height="24px" />
            </Box>
          ) : detail.feedCrops.length !== 0 ? (
            <Box display="flex" flexWrap="wrap" alignItems="center" gap="10px">
              {detail.feedCrops &&
                detail.feedCrops.map((feedCrop) => (
                  <Box
                    key={feedCrop}
                    height="24px"
                    paddingX="14px"
                    minWidth="45px"
                    bgcolor={theme.card.detail.feedCrops[feedCrop]}
                    borderRadius="24px"
                    color={theme.card.button.textColor}
                  >
                    <Typography
                      fontFamily="Noto Sans JP, Roboto, Helvetica, Arial, sans-serif"
                      fontStyle="normal"
                      fontWeight="700"
                      fontSize="14px"
                      lineHeight="22px"
                      letterSpacing="-0.02em"
                      textAlign="center"
                      id="feedCrop"
                    >
                      {textJa.detail.center.feedCrops[feedCrop]}
                    </Typography>
                  </Box>
                ))}
            </Box>
          ) : (
            "-"
          )}
        </Box>
      </Box>
    </SkeletonTheme>
  );
};

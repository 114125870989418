import { Box, Typography } from "@mui/material";
import { MapPin } from "react-feather";
import { theme } from "../../libs/theme";
import { textJa } from "../../locales/textJa";

type EmptyDetailProps = {
  isMobileWide: boolean;
};

export const EmptyDetail = ({ isMobileWide }: EmptyDetailProps) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
      <Box display="flex" justifyContent="center" width="40px" height="40px" color={theme.card.mapIconColor}>
        <MapPin strokeWidth={2} />
      </Box>
      <Typography
        color={theme.card.mainTextColor}
        fontFamily="Noto Sans JP, Roboto, Helvetica, Arial, sans-serif"
        fontWeight="700"
        fontSize={isMobileWide ? "4dvh" : "20px"}
        lineHeight={isMobileWide ? "6dvh" : "38px"}
        marginTop={isMobileWide ? "1dvh" : "37px"}
        textAlign="center"
        width="85%"
        whiteSpace="pre-wrap"
      >
        {`${textJa.initialText.contents}`}
      </Typography>
    </Box>
  );
};

import React from "react";
import { IconButton, Box, Typography } from "@mui/material";
import { Menu, X } from "react-feather";
import { textJa } from "../../locales/textJa";
import { theme } from "../../libs/theme";
import { LogoIcon } from "../Search/LogoIcon";

const titleStyles = {
  fontFamily: "Noto Sans JP, Roboto, Helvetica, Arial, sans-serif",
  color: theme.card.mainTextColor,
  fontSize: "1.8dvh",
  fontWeight: 700,
  lineHeight: "4dvh",
  marginTop: "2dvh",
};

const iconStyles = { width: "6dvh", height: "6dvh" };

type Props = {
  setIsSearchVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isSearchVisible: boolean;
  setIsShowingDetails: React.Dispatch<React.SetStateAction<boolean>>;
};

const HamburgerMenu = ({ setIsSearchVisible, isSearchVisible, setIsShowingDetails }: Props) => {
  const toggleSearch = () => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setIsSearchVisible((prev) => !prev);
    setIsShowingDetails(false);
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pr={2}
        pl={2}
        sx={{
          height: "10dvh",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          backdropFilter: "blur(10px)",
          zIndex: 1,
        }}
      >
        <Box display="flex" flexDirection="column" alignItems="center" paddingBottom="2dvh">
          <Typography sx={titleStyles}>{textJa.title}</Typography>
          <Box>
            <LogoIcon small={true} />
          </Box>
        </Box>
        <IconButton
          edge="end"
          color="inherit"
          aria-label="menu"
          sx={{
            color: "#21963B",
          }}
          onClick={toggleSearch()}
        >
          {isSearchVisible ? <X style={iconStyles} /> : <Menu style={iconStyles} />}
        </IconButton>
      </Box>
    </>
  );
};

export default HamburgerMenu;

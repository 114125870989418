import { Box, Divider, styled, Typography } from "@mui/material";
import { Fragment } from "react";
import { Info, PieChart } from "react-feather";
import { theme } from "../../libs/theme";
import { textJa } from "../../locales/textJa";
import { detailDataType } from "../../types/api";
import { IngredientRow } from "./IngredientRow";
import yen from "../../images/yen.png";
import { NoImage } from "./NoImage";
import { Achievements } from "./Achievements";

const StyledTypography = styled(Typography)(() => ({
  color: theme.card.mainTextColor,
  fontFamily: "Noto Sans JP, Roboto, Helvetica, Arial, sans-serif",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "19px",
}));

type Props = {
  detail: detailDataType;
  loading: boolean;
};

export const Compost = ({ detail, loading }: Props) => {
  return (
    <Box width="100%" display={loading ? "none" : "inline"}>
      {detail.detail.compost.map((compost) => (
        <Fragment key={compost.name}>
          <Divider
            sx={{
              width: "100%",
              borderWidth: "1px",
              solid: theme.card.detail.divider.solid,
              marginBottom: "16px",
            }}
          />
          {/* 堆肥情報 */}
          <Box display="flex" alignItems="center" marginBottom="14px">
            <Box display="flex" justifyContent="center" alignItems="center" color={theme.card.detail.icon}>
              <Info strokeWidth={2} />
            </Box>
            <StyledTypography marginLeft="16px">{textJa.detail.compost.info}</StyledTypography>
          </Box>
          {/* 基本情報 */}
          <Box display="flex" alignItems="center" marginBottom="8px">
            {/* 画像 */}
            <Box display="flex" justifyContent="center" alignItems="center" width="114px" height="114px">
              {compost.image !== null ? (
                <Box width="114px" height="114px" borderRadius="16px" overflow="hidden" sx={{ objectFit: "cover" }}>
                  <img src={`data:image/png;base64,${compost.image}`} alt={compost.name} width="114px" height="114px" />
                </Box>
              ) : (
                <NoImage type="compost" />
              )}
            </Box>
            {/* 名前・説明 */}
            <Box marginLeft="16px" width="calc(100% - 114px)">
              <Typography
                fontFamily="Noto Sans JP, Roboto, Helvetica, Arial, sans-serif"
                fontStyle="normal"
                fontWeight="700"
                fontSize="20px"
                lineHeight="24px"
                color={theme.card.mainTextColor}
              >
                {compost.name}
              </Typography>
              <Typography
                fontFamily="Noto Sans JP, Roboto, Helvetica, Arial, sans-serif"
                fontStyle="normal"
                fontWeight="400"
                fontSize="16px"
                lineHeight="19px"
                color={theme.card.mainTextColor}
                sx={{
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 3,
                  overflow: "hidden",
                }}
              >
                {compost.description !== null ? compost.description : "-"}
              </Typography>
            </Box>
          </Box>
          {/* 価格 */}
          <Box>
            <Box display="flex" alignItems="center" marginBottom="10px">
              <Box display="flex" justifyContent="center" alignItems="center" color={theme.card.detail.icon}>
                <img src={yen} alt={yen} width="24px" />
              </Box>
              <StyledTypography marginLeft="16px">{textJa.detail.compost.price.bagged}</StyledTypography>
            </Box>
            <Box display="flex" alignItems="center" marginBottom="26px">
              <Box display="flex" justifyContent="center" alignItems="center" color={theme.card.detail.icon}>
                <img src={yen} alt={yen} width="24px" />
              </Box>
              <StyledTypography marginLeft="16px">{textJa.detail.compost.price.individually}</StyledTypography>
            </Box>
          </Box>
          {/* 成分表 */}
          <Box display="flex" alignItems="center" marginBottom="16px">
            <Box display="flex" justifyContent="center" alignItems="center" color={theme.card.detail.icon}>
              <PieChart strokeWidth={2} />
            </Box>
            <StyledTypography marginLeft="16px">{textJa.detail.compost.ingredientLabel}</StyledTypography>
          </Box>
          {/* APIレスポンスが配列ではないのでmapやめた */}
          <Box display="grid" rowGap="16px" marginBottom="26px">
            <IngredientRow
              label1={textJa.detail.compost.ingredients.N}
              category1={compost.ingredients.categoryOfN}
              value1={compost.ingredients.N}
              unit1={textJa.detail.compost.ingredientsUnit.N}
              label2={textJa.detail.compost.ingredients.Zn}
              category2={compost.ingredients.categoryOfZn}
              value2={compost.ingredients.Zn}
              unit2={textJa.detail.compost.ingredientsUnit.Zn}
            />
            <IngredientRow
              label1={textJa.detail.compost.ingredients.P}
              category1={compost.ingredients.categoryOfP}
              value1={compost.ingredients.P}
              unit1={textJa.detail.compost.ingredientsUnit.P}
              label2={textJa.detail.compost.ingredients.Ca}
              category2={compost.ingredients.categoryOfCa}
              value2={compost.ingredients.Ca}
              unit2={textJa.detail.compost.ingredientsUnit.Ca}
            />
            <IngredientRow
              label1={textJa.detail.compost.ingredients.K}
              category1={compost.ingredients.categoryOfK}
              value1={compost.ingredients.K}
              unit1={textJa.detail.compost.ingredientsUnit.K}
              label2={textJa.detail.compost.ingredients.CN}
              category2={compost.ingredients.categoryOfCN}
              value2={compost.ingredients.CN}
              unit2={textJa.detail.compost.ingredientsUnit.CN}
            />
            <IngredientRow
              label1={textJa.detail.compost.ingredients.Cu}
              category1={compost.ingredients.categoryOfCu}
              value1={compost.ingredients.Cu}
              unit1={textJa.detail.compost.ingredientsUnit.Cu}
              label2={textJa.detail.compost.ingredients.H2O}
              category2={compost.ingredients.categoryOfH2O}
              value2={compost.ingredients.H2O}
              unit2={textJa.detail.compost.ingredientsUnit.H2O}
            />
          </Box>
          <Achievements detail={compost} loading={loading} />
        </Fragment>
      ))}
    </Box>
  );
};

import { Box, styled, Typography } from "@mui/material";
import { theme } from "../../libs/theme";
import { textJa } from "../../locales/textJa";

const StyledTypography = styled(Typography)(() => ({
  color: theme.card.mainTextColor,
  fontFamily: "Noto Sans JP, Roboto, Helvetica, Arial, sans-serif",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "19px",
}));

type Props = {
  label1: string;
  category1: number | null;
  value1: number | null;
  unit1: string;
  label2: string;
  category2: number | null;
  value2: number | null;
  unit2: string;
};

export const IngredientRow = ({
  label1,
  category1,
  value1,
  unit1,
  label2,
  category2,
  value2,
  unit2,
}: Props) => {
  return (
    <Box display="flex" alignItems="center">
      <Box width="50%" display="flex" alignItems="center">
        <Box
          color={theme.card.detail.ingredients.color}
          bgcolor={theme.card.detail.ingredients.bgColor}
          height="24px"
          borderRadius="24px"
          // Figma上の数値とは異なるが、実際に計測した値
          paddingX="14px"
          // Figmaで設定されている幅73pxからpaddingXを引いた値
          minWidth="31px"
        >
          <Typography
            textAlign="center"
            fontFamily="Noto Sans JP, Roboto, Helvetica, Arial, sans-serif"
            fontStyle="normal"
            fontWeight="700"
            fontSize="14px"
            lineHeight="22px"
            whiteSpace="nowrap"
          >
            {label1}
          </Typography>
        </Box>

        <Box marginLeft="16px">
          <Typography
            fontFamily="Noto Sans JP, Roboto, Helvetica, Arial, sans-serif"
            fontStyle="normal"
            fontWeight="400"
            fontSize="10px"
            lineHeight="12px"
            color={theme.card.subTextColor}
          >
            {category1 !== null
              ? textJa.detail.compost.ingredientsCategory[category1]
              : ""}
          </Typography>
          <StyledTypography>{`${value1 ?? "-"} ${unit1}`}</StyledTypography>
        </Box>
      </Box>
      {label2 && (
        <Box width="50%" display="flex" alignItems="center">
          <Box
            color={theme.card.detail.ingredients.color}
            bgcolor={theme.card.detail.ingredients.bgColor}
            height="24px"
            borderRadius="24px"
            // Figma上の数値とは異なるが、実際に計測した値
            paddingX="14px"
            // Figmaで設定されている幅73pxからpaddingXを引いた値
            minWidth="31px"
          >
            <Typography
              textAlign="center"
              fontFamily="Noto Sans JP, Roboto, Helvetica, Arial, sans-serif"
              fontStyle="normal"
              fontWeight="700"
              fontSize="14px"
              lineHeight="22px"
              whiteSpace="nowrap"
            >
              {label2}
            </Typography>
          </Box>

          <Box marginLeft="16px">
            <Typography
              fontFamily="Noto Sans JP, Roboto, Helvetica, Arial, sans-serif"
              fontStyle="normal"
              fontWeight="400"
              fontSize="10px"
              lineHeight="12px"
              color={theme.card.subTextColor}
            >
              {category2 !== null
                ? textJa.detail.compost.ingredientsCategory[category2]
                : ""}
            </Typography>
            <StyledTypography>{`${value2 ?? "-"} ${unit2}`}</StyledTypography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

import { Box } from "@mui/material";
import { ReactNode } from "react";
import { theme } from "../../libs/theme";

type Props = {
  children: ReactNode;
};

export const ModalCard = ({ children }: Props) => {
  return (
    <Box
      bgcolor={theme.card.bgColor}
      width="529px"
      height="172px"
      position="absolute"
      top="50%"
      left="50%"
      zIndex={2}
      sx={{
        boxShadow: "0px 4px 25px rgba(0, 0, 0, 0.1)",
        backdropFilter: "blur(15px)",
        borderRadius: "24px",
        transform: "translate(-50%, -50%)",
      }}
    >
      {children}
    </Box>
  );
};

import Box from "@mui/material/Box";
import { createContext, useEffect, useState } from "react";
import { Detail } from "./components/Detail/Detail";
import { Map } from "./components/Map/Map";
import { Search } from "./components/Search/Search";
import { initDataResponse, initDataResponses, Pin } from "./types/api";
import { Controls } from "./components/Controls/Controls";
import HamburgerMenu from "./components/Common/HamburgerMenu";
import { pageView } from "./utils/googleAnalytics";
import { ErrorPerformance } from "./components/Error/ErrorPerformance";
import { useMediaQuery, Collapse } from "@mui/material";
import "react-loading-skeleton/dist/skeleton.css";

type ContextType = {
  setIsError: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ErrorContext = createContext<ContextType>({} as ContextType);

export const App = () => {
  const [isError, setIsError] = useState<boolean>(false);
  const [pins, setPins] = useState<Pin[] | []>([]);
  const [allPins, setAllPins] = useState<Pin[] | []>([]);
  const [isShowingDetails, setIsShowingDetails] = useState<boolean>(true);
  const [detailId, setDetailId] = useState<number | null>(null);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [isSearchVisible, setIsSearchVisible] = useState<boolean>(true);

  const isMobile = useMediaQuery("(max-width:600px)");
  const isMobileWide = useMediaQuery("(max-height:500px)");

  const fetchInitialData = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_ENDPOINT || "");
      if (!response.ok) throw new Error("Network response was not ok");
      const allData: initDataResponses = await response.json();
      const initData = allData.centers.map(
        (data: initDataResponse): Pin => ({
          id: data.center_id,
          name: data.center_name,
          lat: data.center_lat,
          lng: data.center_lng,
          acceptableMaterials: data.center_material,
          annualProduction: data.center_annual_production,
          saleForms: data.center_sale,
          availabilityOfScattering: data.center_scatter,
          generalCrops: data.general_crops,
          feedCrops: data.feed_crops,
        })
      );
      setPins(initData);
      setAllPins(initData);
    } catch (error) {
      console.error("Fetch error:", error);
      setIsError(true);
    }
  };

  useEffect(() => {
    fetchInitialData();
    pageView();
  }, []);

  return (
    <ErrorContext.Provider value={{ setIsError }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100dvh",
          position: "relative",
        }}
      >
        {isMobile && (
          <HamburgerMenu
            setIsSearchVisible={setIsSearchVisible}
            isSearchVisible={isSearchVisible}
            setIsShowingDetails={setIsShowingDetails}
          />
        )}
        <Collapse in={!isMobile || isSearchVisible} sx={{ flexGrow: 1, overflow: "auto" }}>
          <Search
            setPins={setPins}
            allPins={allPins}
            isMobile={isMobile}
            isMobileWide={isMobileWide}
            setIsSearchVisible={setIsSearchVisible}
          />
        </Collapse>
        <Controls map={map} isMobile={isMobile} />
        <Detail
          show={isShowingDetails}
          setShow={setIsShowingDetails}
          id={detailId}
          isMobile={isMobile}
          isMobileWide={isMobileWide}
        />
        <Box sx={{ position: "absolute", bottom: 0, width: "100%" }}>
          <Map
            setMap={setMap}
            pins={pins}
            setShow={setIsShowingDetails}
            setId={setDetailId}
            isMobile={isMobile}
            setIsSearchVisible={setIsSearchVisible}
          />
        </Box>
      </Box>

      {isError && <ErrorPerformance />}
    </ErrorContext.Provider>
  );
};

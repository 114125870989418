import { Box } from "@mui/material";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { ErrorContext } from "../../App";
import { Card } from "../../Card";
import { detailDataResponse, detailDataType } from "../../types/api";
import { Center } from "./Center";
import { Compost } from "./Compost";
import { EmptyDetail } from "./EmptyDetail";

type Props = {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  id: number | null;
  isMobile: boolean;
  isMobileWide: boolean;
};

export const Detail = ({ show, setShow, id, isMobile, isMobileWide }: Props) => {
  const { setIsError } = useContext(ErrorContext);
  const [loading, setLoading] = useState<boolean>(false);
  const endpoint = process.env.REACT_APP_ENDPOINT || "";

  const initialDetail = {
    detail: {
      center: {
        image: null,
        name: "",
        jaName: "",
        address: "",
        contact: null,
        establish: null,
        annualProduction: null,
        acceptableMaterials: [],
        saleForms: [],
        availabilityOfScattering: null,
      },
      compost: [
        {
          image: null,
          name: "",
          description: null,
          ingredients: {
            N: null,
            categoryOfN: null,
            K: null,
            categoryOfK: null,
            P: null,
            categoryOfP: null,
            H2O: null,
            categoryOfH2O: null,
            CN: null,
            categoryOfCN: null,
            Cu: null,
            categoryOfCu: null,
            Zn: null,
            categoryOfZn: null,
            Ca: null,
            categoryOfCa: null,
          },
          generalCrops: [],
          feedCrops: [],
        },
      ],
    },
  };

  const [detail, setDetail] = useState<detailDataType>(initialDetail);
  const getDetailData = async (id: number) => {
    try {
      const response = await fetch(`${endpoint}?center_id=${id}`);
      const { detail } = (await response.json()) as detailDataResponse;

      setDetail({
        detail: {
          center: {
            image: detail.center.image,
            name: detail.center.name,
            jaName: detail.center.ja_name,
            address: detail.center.address,
            contact: detail.center.contact,
            establish: detail.center.establish,
            annualProduction: detail.center.annual_production,
            acceptableMaterials: detail.center.material,
            saleForms: detail.center.sale,
            availabilityOfScattering: detail.center.scatter,
          },
          compost: detail.compost.map(({ image, name, description, ingredients, generalCrops, feedCrops }) => ({
            image,
            name,
            description,
            ingredients: {
              N: ingredients.N,
              categoryOfN: ingredients.N_CATEGORY,
              K: ingredients.K,
              categoryOfK: ingredients.K_CATEGORY,
              P: ingredients.P,
              categoryOfP: ingredients.P_CATEGORY,
              H2O: ingredients.H2O,
              categoryOfH2O: ingredients.H2O_CATEGORY,
              CN: ingredients.CN,
              categoryOfCN: ingredients.CN_CATEGORY,
              Cu: ingredients.Cu,
              categoryOfCu: ingredients.Cu_CATEGORY,
              Zn: ingredients.Zn,
              categoryOfZn: ingredients.Zn_CATEGORY,
              Ca: ingredients.Ca,
              categoryOfCa: ingredients.Ca_CATEGORY,
            },
            generalCrops: generalCrops,
            feedCrops: feedCrops,
          })),
        },
      });
      setLoading(false);
    } catch (error) {
      console.error(error);
      setIsError(true);
    }
  };

  useEffect(() => {
    if (id !== null) {
      setLoading(true);
      getDetailData(id);
    }
  }, [id]);

  if (isMobile && id === null) {
    return null;
  }

  return (
    <Card show={show} setShow={setShow} isMobile={isMobile} isMobileWide={isMobileWide}>
      {id === null ? (
        <EmptyDetail isMobileWide={isMobileWide} />
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center" paddingY="14px" paddingX="16px">
          <Center detail={detail} loading={loading} isMobile={isMobile} isMobileWide={isMobileWide} />
          <Compost detail={detail} loading={loading} />
        </Box>
      )}
    </Card>
  );
};

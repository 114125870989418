import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import { Dispatch, SetStateAction } from "react";
import { Pin } from "../../types/api";
import { ErrorPerformance } from "../Error/ErrorPerformance";

const containerStyle = {
  width: "100%",
  height: "100dvh",
};

const options = {
  zoomControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
};

const center = {
  lat: 37.82653,
  lng: 139.44565,
};

type Props = {
  setMap: Dispatch<SetStateAction<google.maps.Map | null>>;
  pins: Pin[] | [];
  setShow: Dispatch<SetStateAction<boolean>>;
  setId: Dispatch<SetStateAction<number | null>>;
  isMobile: boolean;
  setIsSearchVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Map = ({ setMap, pins, setShow, setId, isMobile, setIsSearchVisible }: Props) => {
  const key = process.env.REACT_APP_KEY ? process.env.REACT_APP_KEY : "";

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: key,
  });

  if (loadError) {
    return <ErrorPerformance />;
  }

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={6}
      options={{
        ...options,
      }}
      onLoad={(map) => setMap(map)}
    >
      {pins &&
        pins.map((pin) => (
          <MarkerF
            key={pin.id}
            position={{
              lat: pin.lat,
              lng: pin.lng,
            }}
            onClick={() => {
              setId(pin.id);
              setShow(true);
              isMobile && setIsSearchVisible(false);
            }}
          />
        ))}
    </GoogleMap>
  ) : (
    <></>
  );
};

import { Box } from "@mui/material";
import { Crosshair } from "react-feather";
import { theme } from "../../libs/theme";
import icon from "../../images/icon.png";

type Props = {
  map: google.maps.Map | null;
};

export const CurrentLocation = ({ map }: Props) => {
  return (
    <Box
      sx={{
        width: "32px",
        height: "32px",
        borderRadius: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: theme.card.jaColor,
        background: "rgba(255, 255, 255, 0.6)",
        boxShadow: "0px 4px 25px rgba(0, 0, 0, 0.1)",
        backdropFilter: "blur(15px)",
        ":hover": {
          cursor: "pointer",
        },
      }}
      onClick={() => {
        if (navigator.geolocation && map !== null) {
          navigator.geolocation.getCurrentPosition(
            (position: GeolocationPosition) => {
              const pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };
              map.setCenter(pos);
              map.setZoom(12);
              new google.maps.Marker({
                position: pos,
                map,
                icon: {
                  url: icon,
                  scaledSize: new google.maps.Size(64, 64),
                  anchor: new google.maps.Point(32, 32),
                },
              });
            }
          );
        }
      }}
    >
      <Crosshair width="19px" height="19px" strokeWidth={2} />
    </Box>
  );
};

import { Button } from "@mui/material";
import { useContext } from "react";
import { ErrorContext } from "../../App";
import { theme } from "../../libs/theme";
import { textJa } from "../../locales/textJa";

export const OkButton = () => {
  const { setIsError } = useContext(ErrorContext);

  return (
    <Button
      sx={{
        background: theme.card.button.search.searchBgColor,
        boxShadow: "0px 4px 25px rgba(0, 0, 0, 0.1)",
        borderRadius: "20px",
        width: "136px",
        height: "37px",
        color: theme.card.button.textColor,
        textAlign: "center",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "19px",
      }}
      onClick={() => setIsError(false)}
    >
      {textJa.error.okbutton}
    </Button>
  );
};

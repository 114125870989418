export const theme = {
  card: {
    bgColor: "rgba(255, 255, 255, 0.6)",
    mainTextColor: "#333333",
    subTextColor: "#999999",
    jaColor: "#21963B",
    searchComponents: {
      label: "#999999",
      button: {
        label: "rgba(0, 0, 0, 0.87)",
        borderColor: "rgba(0, 0, 0, 0.26)",
        checkedColor: "#FF505F",
      },
    },
    button: {
      textColor: "#FFFFFF",
      search: {
        searchBgColor: "linear-gradient(91.57deg, #21963B, #98D11D 95.94%)",
        resetBgColor: "#999999",
      },
      close: {
        bgColor: "rgba(51, 51, 51, 0.6)",
      },
    },
    scrollbar: {
      "::-webkit-scrollbar": {
        width: "4px",
      },
      "::-webkit-scrollbar-track": {
        display: "none",
      },
      "::-webkit-scrollbar-thumb": {
        background: "rgba(0, 0, 0, 0.1)",
        borderRadius: "2px",
      },
    },
    mapIconColor: "#FF505F",
    detail: {
      skeleton: "rgba(255, 255, 255, 0.6)",
      icon: "#999999",
      acceptableMaterials: ["#F44336", "#E91E63", "#9C27B0", "#3F51B5", "#009688", "#FFC107", "#607D8B"],
      generalCrops: [
        "#FFC107", // 水稲
        "#EF6C00", // 小麦
        "#AED581", // 大豆
        "#4CAF50", // 茶
        "#9E9E9E", // だいこん
        "#6D4C41", // ばれいしょ
        "#4CAF50", // キャベツ
        "#E64A19", // たまねぎ
        "#D32F2F", // トマト
        "#FFB74D", // みかん
        "#FFA726", // りんご
        "#66BB6A", // アスパラガス
        "#FFC107", // 花き
        "#8E24AA", // ぶどう
        "#757575", // その他
      ],
      feedCrops: [
        "#4CAF50", // 牧草
        "#FFC107", // WCS用稲
        "#AED581", // 飼料用トウモロコシ
        "#6D4C41", // 飼料用デントコーン
        "#757575", // その他
      ],
      ingredients: {
        bgColor: "#999999",
        color: "#FFFFFF",
      },
      divider: {
        solid: "#DDDDDD",
      },
      noImage: {
        color: "#FFFFFF",
        textShadow: {
          center: "0px 0px 4px #000000",
          compost: "1px 1px 4px rgba(0, 0, 0, 0.94)",
        },
      },
    },
  },
};

import { Box } from "@mui/material";
import logo from "../../images/logo.png";

type LogoIconProps = {
  small?: boolean;
};

export const LogoIcon = ({ small }: LogoIconProps) => {
  return (
    <Box>
      <img src={logo} alt="Logo" width={small ? 45 : 100} />
    </Box>
  );
};
